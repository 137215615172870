import Title from '../Title/Title';
import './tabs.css';

interface TabsProps {
    sections: string[];
    selectedTab: string;
    onChangeTab: (selectedTab: string) => void;
    dotIndex?: number;
}

const Tabs: React.FC<TabsProps> = ({
    sections,
    selectedTab,
    onChangeTab,
    dotIndex = -1,
}) => {
    return (
        <div className="tabs">
            {sections.map((section, index) => (
                <div
                    key={section}
                    className="tab-section"
                    onClick={() => onChangeTab(section)}
                >
                    <Title
                        color={selectedTab === section ? 'green' : 'black'}
                        text={section}
                    />
                    {dotIndex !== -1 && dotIndex === index && (
                        <div className="tab-dot" />
                    )}
                </div>
            ))}
        </div>
    );
};

export default Tabs;
