import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { useGeoData } from '../../hooks/geoData/useGeoData';
import { isValidEmail, isValidPassword } from '../../utils/helpers';
import { OptionType, RegisterPayload } from '../../utils/types';
import {
    Select,
    Title,
    TextField,
    TextInput,
    Button,
    GenericError,
    Autocomplete,
} from '../common';
import { LANGUAGES, languageOptions } from '../../utils/consts';
import { register } from '../../api/auth';
import { useTranslations } from '../../hooks/translations/useTranslations';
import EmailCode from '../EmailCode/EmailCode';

import './register.css';

const initialFormValues = {
    name: '',
    surname: '',
    email: '',
    password: '',
    country: '',
    city: '',
    language: LANGUAGES.EN,
};

interface FormValues {
    name: string;
    surname: string;
    email: string;
    password: string;
    country: string;
    city: string;
    language: LANGUAGES;
}

interface Errors {
    name: string | null;
    surname: string | null;
    email: string | null;
    password: string | null;
    country: string | null;
    city: string | null;
    language: string | null;
}

const Register = () => {
    const auth = useAppSelector(state => state.auth);
    const navigate = useNavigate();
    const t = useTranslations();

    const {
        isLoading: isGeoDataLoading,
        error: geoDataError,
        countriesData,
        citiesData,
        getAllCountries,
        getCities,
    } = useGeoData();

    const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
    const [errors, setErrors] = useState<Errors>({
        ...initialFormValues,
        language: null,
    });
    const [countryCode, setCountryCode] = useState('');
    const [cityId, setCityId] = useState('');
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmEmailForm, setShowConfirmEmailForm] = useState(false);

    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            getAllCountries();
        }
    }, [getAllCountries]);

    useEffect(() => {
        if (auth.authData) {
            navigate('/dashboard', { replace: true });
        }
    }, [auth, navigate]);

    const inputChangeHandler = (name: string, value: string) => {
        setFormValues({
            ...formValues,
            [name]: value,
        });
        setErrors({
            ...initialFormValues,
            language: null,
        });
    };

    const onSubmitHandler = async (event: React.FormEvent) => {
        event.preventDefault();

        let hasErrors = false;
        const formErrors = { ...errors };
        let key: keyof FormValues;
        for (key in formValues) {
            if (!formValues[key]) {
                hasErrors = true;
                formErrors[key] = 'This field is required';
            }
        }

        if (hasErrors) {
            setErrors({
                ...formErrors,
            });
            return;
        }

        if (formValues.email && !isValidEmail(formValues.email)) {
            setErrors({ ...errors, email: 'Invalid email address' });
            return;
        }

        if (
            !formValues.password ||
            (formValues.password && !isValidPassword(formValues.password))
        ) {
            setErrors({
                ...errors,
                password:
                    'Password must have at least one uppercase letter, one lowercase, one number and one special character',
            });
            return;
        }

        const payload: RegisterPayload = {
            ...formValues,
            countryCode,
            city: cityId,
        };

        try {
            setIsLoading(true);
            await register(payload);

            setIsLoading(false);
            setShowConfirmEmailForm(true);
        } catch (e: any) {
            if (e.response.status === 409) {
                setErrors({
                    ...formErrors,
                    email: 'An account with this email address already exist',
                });
                setIsLoading(false);
            }
            if (e.response.status === 400) {
                setErrors({
                    ...formErrors,
                    [e.response.data.errors[0].path]:
                        e.response.data.errors[0].msg,
                });
            }
            if (e.response.status === 500) {
                setError(true);
            }
            setIsLoading(false);
        }
    };

    const getCitiesHandler = async (initials: string) => {
        await getCities(countryCode, initials);
    };

    const generateOptions = useMemo(() => {
        const countryOptions: OptionType[] = [];
        const citiesOptions: OptionType[] = [];
        countriesData?.forEach(country => {
            countryOptions.push({
                id: country.code,
                label: country.name,
            });
        });
        citiesData?.forEach(city => {
            citiesOptions.push({
                id: city.id,
                label: city.name,
            });
        });
        return { countryOptions, citiesOptions };
    }, [countriesData, citiesData]);

    const onCountryChange = (fieldName: string, result: OptionType) => {
        setCountryCode(result.id as string);

        setFormValues({
            ...formValues,
            city: '',
            [fieldName]: result.label,
        });

        setErrors({
            ...errors,
            country: null,
            city: null,
        });
    };

    const onCityChange = (fieldName: string, result: OptionType) => {
        setCityId(result.id as string);
        setFormValues({
            ...formValues,
            [fieldName]: result.label,
        });

        setErrors({
            ...errors,
            country: null,
            city: null,
        });
    };

    const onLanguageChange = (fieldName: string, result: OptionType) => {
        setFormValues({
            ...formValues,
            [fieldName]: result.id,
        });
    };

    if (geoDataError || error) {
        return <GenericError />;
    }

    return (
        <main className="register-main">
            {showConfirmEmailForm ? (
                <EmailCode email={formValues.email} />
            ) : (
                <>
                    <div className="register-title">
                        <Title text={t('register.title')} size="xxl" />
                    </div>
                    <form onSubmit={onSubmitHandler} className="register-form">
                        <TextInput
                            label={t('register.name')}
                            name="name"
                            placeholder={t('register.name')}
                            onChange={inputChangeHandler}
                            value={formValues.name}
                            error={errors.name}
                        />
                        <TextInput
                            label={t('register.surname')}
                            name="surname"
                            placeholder={t('register.surname')}
                            onChange={inputChangeHandler}
                            value={formValues.surname}
                            error={errors.surname}
                        />
                        <TextInput
                            label="Email"
                            name="email"
                            placeholder="Email"
                            onChange={inputChangeHandler}
                            value={formValues.email}
                            error={errors.email}
                        />
                        <TextInput
                            label="Password"
                            name="password"
                            placeholder="Password"
                            onChange={inputChangeHandler}
                            value={formValues.password}
                            error={errors.password}
                            type="password"
                        />
                        <Select
                            name="language"
                            options={languageOptions}
                            onSelect={onLanguageChange}
                            value={formValues.language || ''}
                            label={t('register.language')}
                            className="full-width"
                            error={errors.language}
                            isDisabled={auth.isLoading || isGeoDataLoading}
                        />
                        <Select
                            name="country"
                            options={generateOptions.countryOptions}
                            onSelect={onCountryChange}
                            value={countryCode as string}
                            label={t('register.country')}
                            className="full-width"
                            error={errors.country}
                            isDisabled={auth.isLoading || isGeoDataLoading}
                        />
                        {formValues.country && (
                            <Autocomplete
                                name="city"
                                startSearch={getCitiesHandler}
                                isLoading={isGeoDataLoading}
                                error={geoDataError}
                                results={generateOptions.citiesOptions}
                                handleOptionClick={onCityChange}
                                placeholder="Search cities"
                                initialValue={(formValues.city as string) || ''}
                                label="City"
                                className="full-width"
                            />
                        )}
                        <Button
                            type="submit"
                            text={t('register.signUp')}
                            variant="green"
                            disabled={isLoading}
                        />
                    </form>
                    <div className="signin-link">
                        <TextField>{t('register.bottomText')}</TextField>
                        <Link to="/login">
                            <TextField color="green">
                                {t('register.signIn')}
                            </TextField>
                        </Link>
                    </div>
                </>
            )}
        </main>
    );
};

export default Register;
