import { COLOURS } from '../../utils/consts';
import { Icon, TextField, Title } from '../common';
import './notFound.css';

export default function NotFound() {
    return (
        <div className="not-found">
            <div className="not-found-wrapper">
                <Icon name="bx-error" color={COLOURS.RED} size="50px" />
                <Title text="404 - Resource not found" size="l" />
                <TextField>This record has been removed</TextField>
            </div>
        </div>
    );
}
