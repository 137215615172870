import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../store/auth/authSlice';
import defaultAvatar from '../../../images/defaultavatar.png';
import { useAppDispatch } from '../../../store/hooks';
import { useAuth } from '../../../hooks/auth/useAuth';
import { Auth } from '../../../utils/types';
import { Title, TextField } from '../../common';

import './userMenu.css';

interface UserMenuProps {
    authData: Auth | null;
}

const UserMenu = ({ authData }: UserMenuProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const auth = useAuth();

    const [showMenu, setShowMenu] = useState(false);
    const ref = useRef<HTMLImageElement | null>(null);

    useEffect(() => {
        document.addEventListener('mousedown', closeUserMenu);
        return () => {
            document.removeEventListener('mousedown', closeUserMenu);
        };
    });

    const closeUserMenu = (event: MouseEvent) => {
        event.stopPropagation();

        if (
            ref.current !== event.target && //@ts-ignore
            event.target?.className !== 'menu-element' &&
            //@ts-ignore
            event.target?.nodeName !== 'P'
        ) {
            setShowMenu(false);
        }
    };

    const showMenuHandler = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setShowMenu(true);
    };

    const logoutHandler = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setShowMenu(false);
        dispatch(logout());
        navigate('/');
    };

    const navigateToMyProfile = () => {
        setShowMenu(false);
        navigate(`/users/${auth.authData?.username}`);
    };

    const navigateToSettings = () => {
        setShowMenu(false);
        navigate('/settings');
    };

    return (
        <div className="user-menu">
            <img
                className="profile-img m"
                src={authData?.avatarUrl || defaultAvatar}
                alt="avatar"
                data-testid="user-avatar"
                onClick={showMenuHandler}
                ref={ref}
            />
            {showMenu && (
                <ul className="dropdown-menu">
                    <li className="menu-element">
                        <Title text={authData?.name!} size="s" />
                    </li>
                    <li className="menu-element" onClick={navigateToMyProfile}>
                        <TextField size="s">My profile</TextField>
                    </li>
                    <li className="menu-element" onClick={navigateToSettings}>
                        <TextField size="s">Settings</TextField>
                    </li>
                    <li className="menu-element" onClick={logoutHandler}>
                        <TextField size="s">Logout</TextField>
                    </li>
                </ul>
            )}
        </div>
    );
};

export default UserMenu;
