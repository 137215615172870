import { useMemo, useState } from 'react';
import { SearchBarResult } from '../../Header/SearchBar/types';
import { OptionType } from '../../../utils/types';
import { renderUserName } from '../../../utils/helpers';
import { fetchSearchUsersResults } from '../../../api/searchBarAPI';
import {
    acceptInvitationAPI,
    acceptJoinRequestAPI,
    createInvitationAPI,
    rejectInvitationAPI,
    rejectJoinRequestAPI,
} from '../../../api/events';

export const useEventRequests = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [resultsIsLoading, setResultsIsLoading] = useState(false);
    const [results, setResults] = useState<SearchBarResult>();
    const [error, setError] = useState<string | null>(null);

    const generateOptions = useMemo(() => {
        const userOptions: OptionType[] = [];
        results?.users?.forEach(user => {
            userOptions.push({
                id: user.id,
                label: renderUserName(user.name, user.surname),
                image: user.avatarUrl,
            });
        });

        return { users: userOptions };
    }, [results]);

    const fetchResults = async (searchString: string) => {
        setError(null);
        try {
            setResultsIsLoading(true);
            const data = await fetchSearchUsersResults(searchString);
            setResults(data);
        } catch (error: any) {
            console.warn(error);
            setError(error);
        }
        setResultsIsLoading(false);
    };

    async function createInvitation(
        eventId: string,
        userId: string,
        team: string
    ) {
        try {
            setIsLoading(true);
            await createInvitationAPI(eventId, userId, team);
        } catch (error: any) {
            console.warn(error);
            if (error.response.status === 409) {
                setError('This user was already invited.');
            }
            if (error.response.status === 403) {
                setError('Cannot invite yourself to the event.');
            }
            setIsLoading(false);

            throw new Error(error);
        }
        setIsLoading(false);
    }

    async function acceptInvitation(eventId: string) {
        try {
            setIsLoading(true);
            await acceptInvitationAPI(eventId);
        } catch (error: any) {
            console.warn(error);
            if (error.response.status === 409) {
                setError('This user was already invited.');
            }
            if (error.response.status === 403) {
                setError('Cannot invite yourself to the event.');
            }
            setIsLoading(false);

            throw new Error(error);
        }
        setIsLoading(false);
    }

    async function rejectInvitation(eventId: string) {
        try {
            setIsLoading(true);
            await rejectInvitationAPI(eventId);
        } catch (error: any) {
            console.warn(error);
            if (error.response.status === 409) {
                setError('This user was already invited.');
            }
            if (error.response.status === 403) {
                setError('Cannot invite yourself to the event.');
            }
            setIsLoading(false);

            throw new Error(error);
        }
        setIsLoading(false);
    }

    const acceptJoinRequest = async (
        eventId: string,
        joinRequestId: string
    ) => {
        try {
            setIsLoading(true);
            await acceptJoinRequestAPI(eventId, joinRequestId);
        } catch (error: any) {
            console.warn(error);
            setError(error);
        }
        setIsLoading(false);
    };

    const rejectJoinRequest = async (
        eventId: string,
        joinRequestId: string
    ) => {
        try {
            setIsLoading(true);
            await rejectJoinRequestAPI(eventId, joinRequestId);
        } catch (error: any) {
            console.warn(error);
            setError(error);
        }
        setIsLoading(false);
    };

    return {
        isLoading,
        searchOptions: generateOptions,
        error,
        fetchResults,
        acceptJoinRequest,
        rejectJoinRequest,
        resultsIsLoading,
        createInvitation,
        acceptInvitation,
        rejectInvitation,
    };
};
