import { OptionType } from '../../../utils/types';
import TextField from '../TextField/TextField';
import UserAvatar from '../UserAvatar/UserAvatar';

interface AutocompleteOptionsProps {
    error?: string | null;
    isLoading: boolean;
    results: OptionType[] | null;
    inputValue: string;
    onOptionClick: (result: OptionType) => void;
}

const AutocompleteOptions = ({
    error,
    isLoading,
    results,
    inputValue,
    onOptionClick,
}: AutocompleteOptionsProps) => {
    if (error) return null;
    if (isLoading) {
        return (
            <div className="autocomplete-menu-option">
                <TextField>Searching...</TextField>
            </div>
        );
    }
    if (results && results.length === 0) {
        return (
            <div className="autocomplete-menu-option">
                <TextField>No Results found for {inputValue}</TextField>
            </div>
        );
    }
    console.log(results);

    return (
        <>
            {results &&
                results.map((result, index) => (
                    <div
                        className="autocomplete-menu-option"
                        key={`${result.id}_${index}`}
                        onClick={() => onOptionClick(result)}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex={0}
                    >
                        {result.image !== undefined && (
                            <UserAvatar imageSrc={result.image} size="m" />
                        )}
                        <TextField>{result.label}</TextField>
                    </div>
                ))}
        </>
    );
};

export default AutocompleteOptions;
