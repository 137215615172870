import { useEffect, useState } from 'react';
import {
    Link,
    useLocation,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import { isValidEmail } from '../../utils/helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Button from '../common/Button/Button';
import TextInput from '../common/TextInput/TextInput';
import { GenericError, Icon, TextField, Title } from '../common';
import { getConfirmEmailCode, login } from '../../api/auth';
import { getCurrentUser } from '../../store/auth/authSlice';
import { useTranslations } from '../../hooks/translations/useTranslations';

import './login.css';
import { COLOURS } from '../../utils/consts';
import EmailCode from '../EmailCode/EmailCode';

interface LoginFormValues {
    email: string | null;
    password: string | null;
}

interface Errors {
    email: string | null;
    password: string | null;
}

const Login = () => {
    const auth = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const t = useTranslations();
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [authError, setAuthError] = useState<string | null>(null);
    const [showEmailCodeForm, setShowEmailCodeForm] = useState(false);

    const from = location.state?.from?.pathname || '/dashboard';

    useEffect(() => {
        if (auth.authData) {
            navigate(from, { replace: true });
        }
    }, [auth, from, navigate]);

    useEffect(() => {
        if (searchParams.get('email-confirmed')) {
            setShowEmailCodeForm(false);
            setAuthError(null);
        }
    }, [searchParams]);

    const [formValues, setFormValues] = useState<LoginFormValues>({
        email: null,
        password: null,
    });
    const [errors, setErrors] = useState<Errors>({
        email: null,
        password: null,
    });

    const inputChangeHandler = (name: string, value: string) => {
        setFormValues({
            ...formValues,
            [name]: value,
        });
        setErrors({
            email: null,
            password: null,
        });
    };

    const onLoginHandler = async () => {
        if (
            !formValues.email ||
            (formValues.email && !isValidEmail(formValues.email))
        ) {
            setErrors({ ...errors, email: 'Invalid email address' });
            return;
        }

        if (!formValues.password) {
            setErrors({
                ...errors,
                password: 'Enter your password',
            });
            return;
        }

        try {
            setIsLoading(true);
            await login(formValues.email, formValues.password);

            dispatch(getCurrentUser());

            setIsLoading(false);
        } catch (e: any) {
            if (e.response.status === 401) {
                setAuthError('Incorrect email or password');
            }
            if (e.response.status === 403) {
                await getConfirmEmailCode(formValues.email);
                setShowEmailCodeForm(true);
            }
            if (e.response.status === 500) {
                setShowError(true);
            }
            setIsLoading(false);
        }
    };

    if (showError) {
        return <GenericError />;
    }

    return (
        <div className="login-wrapper">
            {showEmailCodeForm ? (
                <EmailCode email={formValues.email as string} />
            ) : (
                <div className="login-main">
                    <div className="login-header">
                        <Title text={t('login.title')} size="xl" />
                    </div>
                    {authError && (
                        <div className="login-error">
                            <Icon
                                name="bx-error"
                                color={COLOURS.RED}
                                size="35px"
                            />
                            <TextField color="red">{authError}</TextField>
                        </div>
                    )}
                    {searchParams.get('email-confirmed') && (
                        <div className="email-confirmed">
                            <div>
                                <Title text="Email confirmed"></Title>
                                <Icon
                                    name="bx-check-circle"
                                    color={COLOURS.GREEN}
                                    size="30px"
                                />
                            </div>
                            <TextField>
                                Your email has been verified, you can now
                                proceed with the login.
                            </TextField>
                        </div>
                    )}
                    <TextInput
                        label="Email"
                        name="email"
                        placeholder={t('login.enterEmail')}
                        onChange={inputChangeHandler}
                        value={formValues.email}
                        error={errors.email}
                        className="full-width"
                    />
                    <TextInput
                        label="Password"
                        name="password"
                        placeholder={t('login.enterPassword')}
                        onChange={inputChangeHandler}
                        value={formValues.password}
                        error={errors.password}
                        className="full-width"
                        type="password"
                    />
                    <div className="login-forgot-password">
                        <Link to="/reset-password">
                            <TextField>Forgot your password?</TextField>
                        </Link>
                    </div>
                    <Button
                        text={t('login.login')}
                        variant="green"
                        onClick={onLoginHandler}
                        disabled={isLoading}
                    />
                    <div className="register-link" data-testid="register-link">
                        <TextField>{t('login.noAccount')}</TextField>
                        <Link to="/register">
                            <TextField color="green">
                                {t('login.createAccount')}
                            </TextField>
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Login;
