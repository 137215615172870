import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import mockRequest from '../../utils/mockRequest';
import { NotificationState, INotification } from '../../utils/types';
import { RootState } from '../store';

const initialState: NotificationState = {
    isLoading: false,
    error: null,
    notifications: [],
};

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        refreshCities(state, action) {
            state.notifications = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(getNotifications.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getNotifications.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
            state.notifications = [];
        });
        builder.addCase(
            getNotifications.fulfilled,
            (state, action: PayloadAction<INotification[]>) => {
                state.notifications = action.payload;
                state.isLoading = false;
            }
        );
        builder.addCase(readNotifications.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(readNotifications.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
            state.notifications = [];
        });
        builder.addCase(
            readNotifications.fulfilled,
            (state, action: PayloadAction<INotification[]>) => {
                state.notifications = action.payload;
                state.isLoading = false;
            }
        );
    },
});

export const { refreshCities } = notificationSlice.actions;
export const selectGeoData = (state: RootState) => state.geoData;

export const getNotifications = createAsyncThunk('notifications', async () => {
    const response = await mockRequest.get('/notifications');
    if (!response.data) {
        throw new Error('response was not ok');
    }
    return response.data;
});

export const readNotifications = createAsyncThunk(
    'notifications/read',
    async () => {
        const response = await mockRequest.get('/notifications/read');
        if (!response.data) {
            throw new Error('response was not ok');
        }
        return response.data;
    }
);

export default notificationSlice.reducer;
