import React, { useState, useRef, useEffect } from 'react';
import { OptionType } from '../../../utils/types';
import TextInput from '../TextInput/TextInput';
import AutocompleteOptions from './AutocompleteOptions';
import Icon from '../Icon/Icon';
import { useDebounce } from 'use-debounce';

import './autocomplete.css';
import TextField from '../TextField/TextField';

type AutocompleteProps = {
    initialValue?: string;
    label?: string;
    error?: string | null;
    placeholder?: string;
    name: string;
    startSearch: (value: string) => Promise<void>;
    handleOptionClick: (field: string, result: OptionType) => void;
    isLoading: boolean;
    results: OptionType[] | null;
    isDisabled?: boolean;
    withReset?: boolean;
    onReset?: () => void;
    className?: string;
};

const Autocomplete = ({
    initialValue = '',
    label,
    placeholder = '',
    error = null,
    name,
    startSearch,
    handleOptionClick,
    isLoading = false,
    results,
    isDisabled = false,
    withReset = false,
    onReset,
    className,
}: AutocompleteProps) => {
    const [inputValue, setInputValue] = useState(initialValue);
    const [localLoading, setLocalLoading] = useState(isLoading);
    const [openMenu, setOpenMenu] = useState(false);
    const [optionClicked, setOptionClicked] = useState(false);
    const searchMenu = useRef<HTMLElement | null>();

    const startSearchHandler = async (value: string) => {
        setLocalLoading(true);
        await startSearch(value);
        setLocalLoading(false);
    };
    const [debouncedValue] = useDebounce(inputValue, 500);

    useEffect(() => {
        setInputValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        if (
            debouncedValue.length >= 3 &&
            debouncedValue !== initialValue &&
            !optionClicked
        ) {
            setOpenMenu(true);
            startSearchHandler(debouncedValue);
        }
    }, [debouncedValue]);

    const closeMenu = (event: MouseEvent) => {
        if (event.currentTarget !== event.target) {
            setOpenMenu(false);
            document.removeEventListener('click', closeMenu);
        }
    };

    const inputChangeHandler = (name: string, value: string) => {
        setInputValue(value);
        setOptionClicked(false);

        if (!value.length) {
            setOpenMenu(false);
            return;
        }

        document.addEventListener('click', closeMenu);
    };

    const openSearchMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (inputValue === initialValue) {
            return;
        }

        if (inputValue !== '') {
            setOpenMenu(true);
            document.addEventListener('click', closeMenu);
        }
    };

    const onOptionClick = (result: OptionType) => {
        handleOptionClick(name, result);
        setInputValue(result.label);
        setOpenMenu(false);
        setOptionClicked(true);
    };

    const onResetClick = () => {
        setInputValue('');
        setOpenMenu(false);
        onReset && onReset();
    };

    return (
        <div className="input-container">
            {label && <label htmlFor={name}>{label}</label>}
            <div className={`autocomplete-container ${className}`}>
                <TextInput
                    icon={<Icon name="bx-search" size="20px" />}
                    value={inputValue}
                    type="text"
                    onChange={inputChangeHandler}
                    onClick={openSearchMenu}
                    name={name}
                    placeholder={placeholder}
                    isDisabled={isDisabled}
                    autoComplete="one-time-code"
                />
                {withReset && (
                    <Icon name="bx-x" size="20px" onClick={onResetClick} />
                )}
                {openMenu && (
                    <div
                        className="autocomplete-menu"
                        ref={element => {
                            searchMenu.current = element;
                        }}
                    >
                        <AutocompleteOptions
                            error={error}
                            isLoading={isLoading || localLoading}
                            results={results}
                            inputValue={inputValue}
                            onOptionClick={onOptionClick}
                        />
                    </div>
                )}
            </div>
            {error && (
                <TextField color="red" size="s">
                    {error}
                </TextField>
            )}
        </div>
    );
};

export default Autocomplete;
