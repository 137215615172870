import { SearchBarResult } from '../components/Header/SearchBar/types';
import mockRequest from '../utils/mockRequest';

export const fetchSearchResults = async (
    searchString: string
): Promise<SearchBarResult> => {
    const results = await mockRequest.get(`/search?query=${searchString}`);
    return results.data;
};

export const fetchSearchUsersResults = async (
    searchString: string
): Promise<SearchBarResult> => {
    const results = await mockRequest.get(
        `/search/users?query=${searchString}`
    );
    return results.data;
};
