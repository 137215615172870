import React, { useState, useRef, useEffect, useMemo } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store/hooks';
import defaultAvatar from '../../../images/defaultavatar.png';
import { useNotifications } from '../../../hooks/notifications/useNotifications';
import { NotificationStatus } from '../../../utils/types';
import { Title, TextField, UserAvatar, Icon } from '../../common';

import './notifications.css';
import { readNotifications } from '../../../store/notification/notificationSlice';

const Notifications = () => {
    const navigate = useNavigate();
    const { notifications } = useNotifications();
    const dispatch = useAppDispatch();

    const [showMenu, setShowMenu] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        document.addEventListener('mousedown', closeNotificationsMenu);
        return () => {
            document.removeEventListener('mousedown', closeNotificationsMenu);
        };
    });

    const closeNotificationsMenu = (event: MouseEvent) => {
        //@ts-ignore
        if (ref.current !== event.target && event.target?.nodeName !== 'P') {
            setShowMenu(false);
        }
    };

    const showMenuHandler = async (event: React.MouseEvent<SVGSVGElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (
            notifications.some(
                notification =>
                    notification.status === NotificationStatus.UNREAD
            )
        ) {
            await dispatch(readNotifications());
        }

        setShowMenu(true);
    };

    const navigateToEvent = (eventId: string) => {
        navigate(`/events/${eventId}`);
        setShowMenu(false);
    };

    const showNotificationDot = useMemo(
        () =>
            notifications.some(
                notification =>
                    notification.status === NotificationStatus.UNREAD
            ),
        [notifications]
    );

    return (
        <div className="notifications" ref={ref}>
            <Icon
                name="bx-bell"
                onClick={showMenuHandler}
                size="23px"
                cursor="pointer"
            />
            {showNotificationDot && <div className="new-notifications-dot" />}

            {showMenu && (
                <ul className="notifications-dropdown-menu">
                    <li className="notifications-title">
                        <Title text="Notifications" size="s" />
                    </li>
                    {notifications.length > 0 ? (
                        notifications.map(notification => (
                            <li
                                className="notifications-menu-element"
                                key={notification.id}
                                onClick={() =>
                                    navigateToEvent(notification.eventId)
                                }
                            >
                                <UserAvatar
                                    imageSrc={
                                        notification.sender.avatarUrl ||
                                        defaultAvatar
                                    }
                                    size="m"
                                />
                                <div>
                                    <TextField size="m">
                                        {notification.text}
                                    </TextField>
                                    <TextField size="xs">{`${formatDistanceToNow(
                                        new Date(notification.date)
                                    )} ago`}</TextField>
                                </div>
                            </li>
                        ))
                    ) : (
                        <li className="notifications-menu-element">
                            <TextField size="s">
                                There are no notifications
                            </TextField>
                        </li>
                    )}
                </ul>
            )}
        </div>
    );
};

export default Notifications;
