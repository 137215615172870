import { useState } from 'react';
import TextField from '../TextField/TextField';
import './radioButton.css';
import { capitalizeFirstLetter } from '../../../utils/helpers';
import Title from '../Title/Title';

type RadioOptions = {
    title: string;
    name: string;
    tooltip: string;
};

interface RadioButtonProps {
    label: string;
    onChange: (selelctedOption: string) => void;
    checked: boolean;
    options: RadioOptions[];
}

const RadioButton: React.FC<RadioButtonProps> = ({
    label,
    onChange,
    options,
}) => {
    const [selectedOption, setSelectedOption] = useState(options[0].name);

    const handleOptionChange = (
        changeEvent: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSelectedOption(changeEvent.target.value);
        onChange(changeEvent.target.value);
    };

    return (
        <div className="radio-button-wrapper">
            <Title text={label} size="s" />
            <div className="radio-button-options">
                {options.map(option => (
                    <div
                        key={`radio_${option.name}`}
                        className={`radio-button ${
                            selectedOption === option.name ? 'checked' : ''
                        }`}
                    >
                        <div>
                            <label htmlFor={option.name}>
                                <Title size="s" text={option.title} />
                            </label>
                            <TextField size="s">{option.tooltip}</TextField>
                        </div>

                        <input
                            type="radio"
                            id={option.name}
                            name={option.name}
                            value={option.name}
                            checked={selectedOption === option.name}
                            onChange={handleOptionChange}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RadioButton;
