import { useCallback, useEffect, useState } from 'react';
import { IBoardMessage } from '../../../utils/types';
import {
    createBoardMessageAPI,
    fetchBoardMessagesAPI,
} from '../../../api/board';
import { useTranslations } from '../../../hooks/translations/useTranslations';

export const useEventBoard = (
    eventId: string | undefined,
    isEventCancelled: boolean
) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [boardMessages, setBoardMessages] = useState<IBoardMessage[]>([]);
    const [newMessage, setNewMessage] = useState('');
    const t = useTranslations();

    const fetchBoardMessages = useCallback(async () => {
        if (eventId) {
            try {
                setIsLoading(true);
                const response = await fetchBoardMessagesAPI(eventId);

                setBoardMessages(response);
            } catch (error: any) {
                console.warn(error);
                setError(error);
            }
            setIsLoading(false);
        }
    }, [eventId]);

    useEffect(() => {
        fetchBoardMessages();
    }, [fetchBoardMessages]);

    const onMessageType = (_: string, newValue: string) => {
        setError(null);
        setNewMessage(newValue);
    };

    const onEnterPress = async (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (isEventCancelled) {
            return;
        }

        if (isLoading) {
            return;
        }

        if (!newMessage) {
            setError(t('errors.emptyMessage'));
            return;
        }

        if (event.key !== 'Enter') {
            return;
        }
        try {
            setIsLoading(true);
            if (eventId) {
                const response = await createBoardMessageAPI(
                    eventId,
                    newMessage
                );
                setNewMessage('');
                setBoardMessages(response);
            }

            setIsLoading(false);
        } catch (error: any) {
            console.warn(error);
            setError(error.response.data.error);
            setIsLoading(false);
        }
    };

    const onWriteMessage = async (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (isEventCancelled) {
            return;
        }

        if (isLoading) {
            return;
        }

        if (!newMessage) {
            setError('Cannot send an empty message');
            return;
        }
        if (event.key !== 'Enter') {
            return;
        }
        try {
            setIsLoading(true);
            if (eventId) {
                const response = await createBoardMessageAPI(
                    eventId,
                    newMessage
                );
                setNewMessage('');
                setBoardMessages(response);
            }

            setIsLoading(false);
        } catch (error: any) {
            console.warn(error);
            setError(error.response.data.error);
            setIsLoading(false);
        }
    };

    return {
        error,
        boardMessages,
        isLoading,
        newMessage,
        onMessageType,
        onEnterPress,
        onWriteMessage,
    };
};
