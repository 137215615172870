import mockRequest from '../utils/mockRequest';

export const fetchCountries = async (countryInitials: string) => {
    const results = await mockRequest.get(
        `/countries?initials=${countryInitials}`
    );
    return results.data;
};

export const fetchAllCountries = async () => {
    const results = await mockRequest.get('/countries');
    return results.data;
};

export const fetchCountry = async (countryCode: string) => {
    const results = await mockRequest.get(`/countries/${countryCode}`);
    return results.data;
};

export const fetchCities = async (
    countryCode: string,
    cityInitials: string
) => {
    const results = await mockRequest.get(
        `/cities?country=${countryCode}&query=${cityInitials}`
    );
    return results.data;
};

export const fetchAllCountryCities = async (countryCode: string) => {
    const results = await mockRequest.get(
        `/geo-data/cities?countryCode=${countryCode}`
    );
    return results.data;
};
