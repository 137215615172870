import mockRequest from '../utils/mockRequest';
import { CreateEventPayload } from '../utils/types';

export const fetchEventDetails = async (eventId: string) => {
    const details = await mockRequest.get(`/events/${eventId}`);
    return details.data;
};

export const fetchEvents = async (
    city: string,
    startDate: string,
    limit: string,
    type?: string
) => {
    const details = await mockRequest.get(
        `/events?city=${city}${
            type ? `&type=${type}` : ''
        }&date=${startDate}&limit=${limit}`
    );
    return details.data;
};

export const fetchNextEvents = async () => {
    const record = await mockRequest.get(`/events/get-next`);
    return record.data;
};

export const joinEventAPI = async (eventId: string, teamName: string) => {
    const record = await mockRequest.patch(
        `/events/${eventId}/join/${teamName}`
    );
    return record.data;
};

export const leaveEventAPI = async (eventId: string) => {
    const record = await mockRequest.patch(`/events/${eventId}/leave`);
    return record.data;
};

export const changeTeamAPI = async (eventId: string, teamId: string) => {
    const record = await mockRequest.patch(
        `/events/${eventId}/${teamId}/change`
    );
    return record.data;
};

export const requestJoinAPI = async (eventId: string) => {
    const record = await mockRequest.post(`/events/${eventId}/join-requests`);
    return record.data;
};

export const acceptJoinRequestAPI = async (
    eventId: string,
    joinRequestId: string
) => {
    const record = await mockRequest.patch(
        `/events/${eventId}/join-requests/${joinRequestId}`
    );
    return record.data;
};

export const rejectJoinRequestAPI = async (
    eventId: string,
    joinRequestId: string
) => {
    const record = await mockRequest.patch(
        `/events/${eventId}/requests/reject`,
        {
            joinRequestId,
        }
    );
    return record.data;
};

export const createEventAPI = async (eventPayload: CreateEventPayload) => {
    const record = await mockRequest.post(`/events`, eventPayload);
    return record.data;
};

export const updateEventAPI = async (
    eventId: string,
    eventPayload: CreateEventPayload
) => {
    const record = await mockRequest.put(`/events/${eventId}`, eventPayload);

    return record.data;
};

export const cancelEventAPI = async (eventId: string) => {
    const record = await mockRequest.patch(`/events/${eventId}/cancel`);

    return record.data;
};

export const startVotingPhaseAPI = async (eventId: string) => {
    const record = await mockRequest.patch(`/events/${eventId}/voting`);

    return record.data;
};

export const voteEventAPI = async (
    eventId: string,
    likes: string[],
    winner: string | null
) => {
    const record = await mockRequest.patch(`/events/${eventId}/vote`, {
        likes,
        winner,
    });
    return record.data;
};

export const writeBoardMessageAPI = async (
    eventId: string,
    message: string
) => {
    const record = await mockRequest.patch(`/events/${eventId}/board`, {
        eventId,
        message,
    });
    return record.data;
};

export const reportUserAPI = async (eventId: string, userId: string) => {
    const record = await mockRequest.patch(`/events/${eventId}/report`, {
        userId,
    });
    return record.data;
};

export const createInvitationAPI = async (
    eventId: string,
    userId: string,
    team: string
) => {
    await mockRequest.post(`/events/${eventId}/invitations`, {
        userId,
        team,
    });
};

export const acceptInvitationAPI = async (eventId: string) => {
    await mockRequest.put(`/events/${eventId}/invitations/accept`);
};

export const rejectInvitationAPI = async (eventId: string) => {
    await mockRequest.put(`/events/${eventId}/invitations/reject`);
};
