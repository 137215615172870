import { IEvent, OptionType } from '../../../../utils/types';
import { Autocomplete, Button, Icon, Title } from '../../../common';
import { useTranslations } from '../../../../hooks/translations/useTranslations';
import { COLOURS } from '../../../../utils/consts';
import { useEventRequests } from '../useEventRequests';
import { useEffect, useState } from 'react';

interface EventInvitationDialogProps {
    isOpen: boolean;
    eventDetails: IEvent;
    isOwner: boolean;
    onClose: () => void;
    selectedTeam: string;
    refreshEventData: () => void;
}

const EventInvitationDialog = ({
    isOpen,
    eventDetails,
    onClose,
    selectedTeam,
    refreshEventData,
}: EventInvitationDialogProps) => {
    const t = useTranslations();
    const {
        searchOptions,
        isLoading,
        error,
        fetchResults,
        resultsIsLoading,
        createInvitation,
        acceptInvitation,
    } = useEventRequests();

    const [userId, setUserId] = useState('');

    useEffect(() => {
        setUserId('');
    }, [isOpen]);

    async function createInvitationHandler() {
        try {
            await createInvitation(eventDetails.id, userId, selectedTeam);
            refreshEventData();
            onClose();
        } catch (error: any) {
            console.log('ERROR', error);
        }
    }

    async function acceptInvitationHandler() {
        try {
            await acceptInvitation(eventDetails.id);
            refreshEventData();
            onClose();
        } catch (error: any) {
            console.log('ERROR', error);
        }
    }

    function onUserSelection(_: string, result: OptionType) {
        setUserId(result.id as string);
    }

    if (!isOpen) {
        return null;
    }

    console.log(error);

    return (
        <div className="dialog-overlay">
            <div className="dialog-container">
                <div className="dialog-header">
                    <Icon
                        name="bx-x"
                        color={COLOURS.GREY}
                        size="30px"
                        cursor="pointer"
                        onClick={onClose}
                    />
                    <Title text="Invite user"></Title>
                </div>
                <div className="dialog-body">
                    {/* <EventInvitationsInput
                        startSearch={fetchResults}
                        results={searchOptions}
                        isLoading={resultsIsLoading}
                        error={error}
                        createInvitation={createInvitationHandler}
                    /> */}
                    <Autocomplete
                        name="userId"
                        startSearch={fetchResults}
                        isLoading={resultsIsLoading}
                        error={userId && error}
                        results={searchOptions.users}
                        handleOptionClick={onUserSelection}
                        placeholder="Search users to invite"
                        className="full-width"
                        isDisabled={isLoading}
                    />
                </div>
                <div className="dialog-footer">
                    <Button
                        type="button"
                        text="Confirm"
                        variant="green"
                        onClick={createInvitationHandler}
                        size="md"
                        disabled={!userId || isLoading}
                    />
                </div>
            </div>
        </div>
    );
};

export default EventInvitationDialog;
