import { useState } from 'react';
import { Button, GenericError, Dialog } from '../../common';
import VoteEventTeams from './VoteEventTeams';
import { IEvent, TeamUser } from '../../../utils/types';
import { useVoteEvent } from './useVoteEvent';

import './voteEvent.css';

interface VoteEventProps {
    eventDetails: IEvent;
    onConfirmVote: (likes: string[], winner: string | null) => void;
    isLoading: boolean;
    foundUserInTeam?: TeamUser;
}

export default function VoteEvent({
    eventDetails,
    onConfirmVote,
    isLoading,
    foundUserInTeam,
}: VoteEventProps) {
    const { error, reportUser, updatedEvent, isReportLoading } = useVoteEvent(
        eventDetails.id
    );

    const currentEventData = updatedEvent
        ? { ...updatedEvent }
        : { ...eventDetails };

    const [winner, setWinner] = useState<string | null>(null);
    const [likes, setLikes] = useState<string[]>([]);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    const hasUserVoted = foundUserInTeam?.hasVoted;
    const showConfirmVoteButton = !hasUserVoted;

    const onMarkAsWinner = (teamId: string) => {
        setWinner(teamId);
    };

    const onLikeClick = (userId: string) => {
        const tmpLikes = [...likes];
        if (likes.includes(userId)) {
            tmpLikes.splice(tmpLikes.indexOf(userId), 1);
        } else {
            tmpLikes.push(userId);
        }

        setLikes(tmpLikes);
    };

    const onConfirmVoteHandler = async () => {
        onConfirmVote(likes, winner);
    };

    const onConfirmReport = (userId: string) => {
        reportUser(userId);
    };

    // TODO: loader
    if (isLoading || !eventDetails) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <GenericError />;
    }

    return (
        <div className="vote-event-teams-wrapper">
            {showConfirmVoteButton && (
                <Button
                    text="Confirm vote"
                    size="md"
                    variant="green"
                    onClick={() => setShowConfirmDialog(true)}
                    disabled={likes.length === 0 && !winner}
                />
            )}
            <VoteEventTeams
                eventDetails={currentEventData}
                likes={likes}
                winner={winner}
                onMarkAsWinner={onMarkAsWinner}
                onLikeClick={onLikeClick}
                onConfirmReport={onConfirmReport}
                isReportLoading={isReportLoading}
                foundUserInTeam={foundUserInTeam}
            />
            <Dialog
                isOpen={showConfirmDialog}
                title="Confirm vote"
                bodyText="Are you sure you finished voting?"
                onClose={() => setShowConfirmDialog(false)}
                onConfirm={onConfirmVoteHandler}
                isLoading={isLoading}
            />
        </div>
    );
}
