import axios from 'axios';
import globalRouter from './globalRouter';

const noAuthRequest = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

noAuthRequest.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 404 && globalRouter.navigate) {
            globalRouter.navigate('/not-found');
        }

        return Promise.reject(error);
    }
);

export default noAuthRequest;
