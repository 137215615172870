import { useEffect, useMemo, useState } from 'react';
import { fetchEvents } from '../../api/events';
import { UnpopulatedEvent } from '../../utils/types';
import { useGeoData } from '../../hooks/geoData/useGeoData';

export const useFindEvents = (
    city: string,
    countryCode: string,
    startDate: Date | null,
    eventType?: string
) => {
    const { isLoading: isGeoDataLoading, getCities, citiesData } = useGeoData();

    const [isLoading, setIsLoading] = useState(false);
    const [moreIsLoading, setMoreIsLoading] = useState(false);

    const [events, setEvents] = useState<UnpopulatedEvent[] | null>(null);
    const [eventCount, setEventCount] = useState(0);
    const [take, setTake] = useState(10);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchData() {
            const date = startDate
                ? startDate.toISOString().split('T')[0]
                : new Date().toISOString().split('T')[0];
            try {
                setIsLoading(true);
                const data = await fetchEvents(city, date, take, eventType);
                setEvents(data.events);
                setEventCount(data.count);
            } catch (error: any) {
                console.warn(error);
                setError(error);
            }

            setIsLoading(false);
        }
        fetchData();
    }, [city, eventType, startDate]);

    const showLoadMoreButton = useMemo(
        () => (events?.length ? events?.length === take : false),
        [events, eventCount]
    );

    const onShowMoreClick = async () => {
        const newTake = take + 10;
        const date = startDate
            ? startDate.toISOString().split('T')[0]
            : new Date().toISOString().split('T')[0];
        try {
            setMoreIsLoading(true);
            const data = await fetchEvents(city, date, newTake, eventType);
            setEvents(data.events);
            setEventCount(data.count);
            setMoreIsLoading(false);
        } catch (error: any) {
            console.warn(error);
            setError(error);
        }
        setTake(newTake);
    };

    const getCitiesHandler = async (initials: string) => {
        await getCities(countryCode, initials);
    };

    return {
        isLoading,
        isGeoDataLoading,
        events,
        error,
        showLoadMoreButton,
        onShowMoreClick,
        moreIsLoading,
        getCitiesHandler,
        citiesData,
    };
};
