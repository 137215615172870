import { useAppSelector } from '../../store/hooks';
import { format } from 'date-fns';
import { IEvent } from '../../utils/types';
import Button from '../common/Button/Button';
import UserAvatar from '../common/UserAvatar/UserAvatar';
import { Title, TextField, Icon } from '../common';
import { COLOURS, eventTypeMap } from '../../utils/consts';
import { useTranslations } from '../../hooks/translations/useTranslations';
import { renderUserName } from '../../utils/helpers';
import { useEventRequests } from './EventRequests/useEventRequests';

interface EventHeaderProps {
    eventDetails: IEvent;
    hasUserJoined: boolean;
    onShowCancelDialog: () => void;
    onLeaveEvent: () => void;
    onRequestToJoin: () => void;
    onGoToEditPage: () => void;
    refreshEventData: () => void;
}

const EventHeader: React.FC<EventHeaderProps> = ({
    eventDetails,
    hasUserJoined,
    onShowCancelDialog,
    onLeaveEvent,
    onRequestToJoin,
    onGoToEditPage,
    refreshEventData,
}) => {
    const t = useTranslations();
    const { isLoading, error, acceptInvitation, rejectInvitation } =
        useEventRequests();

    const auth = useAppSelector(state => state.auth);

    const isOwner = eventDetails.owner.id === auth.authData?.id;
    const voteEndDate = eventDetails.voteEndDate
        ? format(new Date(eventDetails.voteEndDate), 'dd/MM/yyyy, HH:mm')
        : null;

    const showJoinRequestButton =
        !hasUserJoined &&
        !isOwner &&
        eventDetails.isPrivate &&
        !eventDetails.joinRequests.some(
            req => req.user.id === auth.authData?.id
        );

    const showInvitationActions = !!eventDetails?.teams.find(team =>
        team.users.some(
            user => user.id === auth.authData?.id && user.isInvitation
        )
    );

    const onAcceptInvitation = async () => {
        try {
            await acceptInvitation(eventDetails.id);
            refreshEventData();
        } catch (error: any) {
            console.log('ERROR', error);
        }
    };

    const onRejectInvitation = async () => {
        try {
            await rejectInvitation(eventDetails.id);
            refreshEventData();
        } catch (error: any) {
            console.log('ERROR', error);
        }
    };

    if (eventDetails.isCancelled || eventDetails.isCancelledBySystem) {
        return (
            <div className="event-details-header">
                <UserAvatar size="xl" imageSrc={eventDetails.owner.avatarUrl} />
                <div className="event-details-header-info">
                    <Title
                        text={`${eventTypeMap[eventDetails.type]} - ${
                            eventDetails.name
                        }`}
                        size="l"
                    />
                    <div className="flex gap">
                        <Icon name="bx-error" color={COLOURS.RED} />
                        <TextField color="red">
                            {eventDetails.isCancelled &&
                                t('eventDetails.eventCancelledByOwner')}
                            {eventDetails.isCancelledBySystem &&
                                t('eventDetails.eventCancelledBySystem')}
                        </TextField>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="event-details-header">
            <UserAvatar size="xl" imageSrc={eventDetails.owner.avatarUrl} />
            <div className="event-details-subheader">
                <div className="event-details-header-info">
                    <Title
                        text={`${eventTypeMap[eventDetails.type]} - ${
                            eventDetails.name
                        }`}
                        size="l"
                    />
                    <TextField size="m">
                        {renderUserName(
                            eventDetails.owner.name,
                            eventDetails.owner.surname
                        )}
                    </TextField>
                    {eventDetails.isVotingPhase && (
                        <TextField
                            size="m"
                            color="green"
                        >{`Vote ends: ${voteEndDate}`}</TextField>
                    )}
                </div>
                <div className="event-details-header-invitation">
                    {showInvitationActions && (
                        <div>
                            <Title
                                text="You were invited to this event"
                                weight="regular"
                                size="s"
                                color="secondary-text"
                            ></Title>
                            <div className="event-details-header-invitation-actions">
                                <Button
                                    text="Accept"
                                    onClick={onAcceptInvitation}
                                    variant="green"
                                    size="sm"
                                />
                                <Button
                                    text="Refuse"
                                    onClick={onRejectInvitation}
                                    variant="transparent"
                                    size="sm"
                                />
                            </div>
                        </div>
                    )}
                </div>
                {!eventDetails.isVotingPhase &&
                    !eventDetails.isClosed &&
                    !showInvitationActions && (
                        <div className="event-details-actions">
                            {isOwner && (
                                <>
                                    <Icon
                                        name="bx-edit"
                                        size="28px"
                                        cursor="pointer"
                                        title="Edit event"
                                        onClick={onGoToEditPage}
                                    />
                                    <Icon
                                        name="bx-trash"
                                        size="28px"
                                        cursor="pointer"
                                        title="Cancel event"
                                        onClick={onShowCancelDialog}
                                    />
                                </>
                            )}
                            {hasUserJoined && (
                                <Icon
                                    name="bx-exit"
                                    size="28px"
                                    cursor="pointer"
                                    title="Leave event"
                                    onClick={onLeaveEvent}
                                />
                            )}
                            {showJoinRequestButton && (
                                <Button
                                    text={t('common.requestJoin')}
                                    onClick={onRequestToJoin}
                                    variant="green"
                                    size="sm"
                                />
                            )}
                        </div>
                    )}
            </div>
        </div>
    );
};

export default EventHeader;
