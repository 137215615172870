import { useNavigate } from 'react-router-dom';
import { IEvent, ITeam } from '../../utils/types';
import UserAvatar from '../common/UserAvatar/UserAvatar';
import { Icon, TextField, Title } from '../common';
import { useAppSelector } from '../../store/hooks';
import { COLOURS } from '../../utils/consts';
import { useTranslations } from '../../hooks/translations/useTranslations';
import { renderUserName } from '../../utils/helpers';

import './eventDetails.css';
import EventInvitationDialog from './EventRequests/EventInvitations/EventInvitationDialog';
import { useState } from 'react';

interface EventDetailsTeamsProps {
    eventDetails: IEvent;
    onJoinEvent: (teamId: string) => void;
    showJoinEventButton: boolean;
    isLoading: boolean;
    isOwner: boolean;
    refreshEventData: () => void;
}

const EventDetailsTeams = ({
    eventDetails,
    onJoinEvent,
    showJoinEventButton,
    isLoading,
    isOwner,
    refreshEventData,
}: EventDetailsTeamsProps) => {
    const navigate = useNavigate();
    const auth = useAppSelector(state => state.auth);
    const t = useTranslations();
    const [showInvitationDialog, setShowInvitationDialog] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState('');

    const userClickHandler = (userId: string) => {
        navigate(`/users/${userId}`);
    };

    function onCreateInvitationClick(team: string) {
        setSelectedTeam(team);
        setShowInvitationDialog(true);
    }

    const renderEmptySlots = (team: ITeam) => {
        const otherTeam = eventDetails.teams.find(
            otherTeam => otherTeam.name !== team.name
        );

        const isEventerInCurrentTeam = !!team?.users.find(
            user => user.id === auth.authData?.id
        );

        const isEventerInOtherTeam = !!otherTeam?.users.find(
            user => user.id === auth.authData?.id
        );

        const maxCounter = eventDetails.maxUsers / 2 - team.users.length;

        const jsx = [];
        let joinAdded = false;
        let changeTeamAdded = false;
        let nextEmptySlotIndex = -1;

        for (let i = 0; i < maxCounter; i++) {
            if (
                !isEventerInCurrentTeam &&
                !isEventerInOtherTeam &&
                !joinAdded &&
                !eventDetails.isCancelled &&
                !eventDetails.isCancelledBySystem &&
                showJoinEventButton
            ) {
                joinAdded = true;
                nextEmptySlotIndex = i + 1;
                jsx.push(
                    <div
                        className="event-details-teams-empty"
                        key={`${team.id}_${i}`}
                    >
                        <div className="action">
                            <Icon
                                name="bx-plus"
                                onClick={() => onJoinEvent(team.name)}
                                color={COLOURS.GREEN}
                                size="30px"
                                cursor="pointer"
                            />
                            <Title text={t('common.join')} size="s" />
                        </div>
                    </div>
                );
            } else if (
                !isEventerInCurrentTeam &&
                isEventerInOtherTeam &&
                !eventDetails.isPartial &&
                !changeTeamAdded &&
                !eventDetails.isCancelled &&
                !eventDetails.isCancelledBySystem
            ) {
                changeTeamAdded = true;
                nextEmptySlotIndex = i + 1;
                jsx.push(
                    <div
                        className="event-details-teams-empty"
                        key={`${team.id}_${i}`}
                    >
                        <div className="action">
                            <Icon
                                name="bx-transfer"
                                onClick={() => onJoinEvent(team.name)}
                                color={COLOURS.GREEN}
                                size="30px"
                                cursor="pointer"
                            />
                            <Title text={t('common.changeTeam')} size="s" />
                        </div>
                    </div>
                );
            } else {
                if (
                    isOwner &&
                    !eventDetails.isCancelled &&
                    !eventDetails.isCancelledBySystem &&
                    (nextEmptySlotIndex === i || nextEmptySlotIndex === -1)
                ) {
                    jsx.push(
                        <div
                            className="event-details-teams-empty"
                            key={`${team.id}_${i}`}
                        >
                            <div className="action">
                                <Icon
                                    name="bx-user-plus"
                                    onClick={() =>
                                        onCreateInvitationClick(team.name)
                                    }
                                    color={COLOURS.GREEN}
                                    size="30px"
                                    cursor="pointer"
                                />
                                <Title text="Invite user" size="s" />
                            </div>
                        </div>
                    );
                } else {
                    jsx.push(
                        <div
                            className="event-details-teams-empty"
                            key={`${team.id}_${i}`}
                        >
                            <div>
                                <TextField>{t('common.empty')}</TextField>
                            </div>
                        </div>
                    );
                }

                nextEmptySlotIndex = i;
            }
        }
        return jsx;
    };

    return (
        <div className="event-details-teams">
            {eventDetails.teams.map((team, index) => (
                <div key={team.name} className="event-details-team">
                    {!eventDetails.isPartial && (
                        <Title
                            text={`${t('common.team')} ${
                                index === 0 ? 'A' : 'B'
                            }`}
                            size="l"
                        />
                    )}
                    {isLoading ? (
                        <div>Loading...</div>
                    ) : (
                        <>
                            {team.users.map(user => (
                                <div
                                    key={user.id}
                                    className="event-details-user"
                                >
                                    <UserAvatar
                                        imageSrc={user.avatarUrl}
                                        size="l"
                                        onClick={() =>
                                            userClickHandler(user.id)
                                        }
                                    />
                                    <TextField>
                                        {renderUserName(
                                            user.name,
                                            user.surname
                                        )}
                                    </TextField>
                                    {user.isInvitation && (
                                        <div className="event-details-team-user-invitation-label">
                                            <TextField
                                                size="xs"
                                                weight="bold"
                                                color="white"
                                            >
                                                Invited
                                            </TextField>
                                        </div>
                                    )}
                                </div>
                            ))}
                            {renderEmptySlots(team)}
                        </>
                    )}
                </div>
            ))}
            <EventInvitationDialog
                isOpen={showInvitationDialog}
                eventDetails={eventDetails}
                onClose={() => setShowInvitationDialog(false)}
                isOwner={isOwner}
                selectedTeam={selectedTeam}
                refreshEventData={refreshEventData}
            />
        </div>
    );
};

export default EventDetailsTeams;
