import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isValidEmail, isValidPassword } from '../../utils/helpers';
import { useAppSelector } from '../../store/hooks';
import Button from '../common/Button/Button';
import TextInput from '../common/TextInput/TextInput';
import { GenericError, TextField, Title } from '../common';
import { getResetPasswordToken, resetPassword } from '../../api/auth';
import { useTranslations } from '../../hooks/translations/useTranslations';

import './resetPassword.css';

interface ResetPasswordFormValues {
    email: string | null;
    code: string | null;
    password: string | null;
    confirmPassword: string | null;
}

interface Errors {
    email: string | null;
    code: string | null;
    confirmPassword: string | null;
    password: string | null;
}

export default function ResetPassword() {
    const auth = useAppSelector(state => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const t = useTranslations();
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showPasswordForm, setShowPasswordForm] = useState(false);

    const from = location.state?.from?.pathname || '/dashboard';

    useEffect(() => {
        if (auth.authData) {
            navigate(from, { replace: true });
        }
    }, [auth, from, navigate]);

    const [formValues, setFormValues] = useState<ResetPasswordFormValues>({
        email: null,
        code: null,
        confirmPassword: null,
        password: null,
    });
    const [errors, setErrors] = useState<Errors>({
        email: null,
        code: null,
        confirmPassword: null,
        password: null,
    });

    const inputChangeHandler = (name: string, value: string) => {
        setFormValues({
            ...formValues,
            [name]: value,
        });
        setErrors({
            email: null,
            code: null,
            confirmPassword: null,
            password: null,
        });
    };

    const onSendCode = async () => {
        if (
            !formValues.email ||
            (formValues.email && !isValidEmail(formValues.email))
        ) {
            setErrors({ ...errors, email: 'Invalid email address' });
            return;
        }

        try {
            setIsLoading(true);
            await getResetPasswordToken(formValues.email);

            setIsLoading(false);
            setShowPasswordForm(true);
        } catch (e: any) {
            if (e.response.status === 400) {
                setErrors({
                    ...errors,
                    ...e.response.data,
                });
                setIsLoading(false);
            }
            if (e.response.status === 401) {
                setErrors({
                    ...errors,
                    email: 'Email eddress not found',
                });
                setIsLoading(false);
            }
            if (e.response.status === 500) {
                setShowError(true);
            }
            setIsLoading(false);
        }
    };

    const onResetPassword = async () => {
        if (!formValues.password) {
            setErrors({
                ...errors,
                password: 'This field is required',
            });
            return;
        }

        if (formValues.password && !isValidPassword(formValues.password)) {
            setErrors({
                ...errors,
                password:
                    'Password must have at least one uppercase letter, one lowercase, one number and one special character',
            });
            return;
        }

        if (formValues.password !== formValues.confirmPassword) {
            setErrors({
                ...errors,
                password: 'Passwords must match',
                confirmPassword: 'Passwords must match',
            });
            return;
        }

        if (!formValues.code) {
            setErrors({
                ...errors,
                code: 'This field is required',
            });
            return;
        }

        try {
            setIsLoading(true);
            await resetPassword(
                formValues.email!,
                formValues.code,
                formValues.password
            );

            navigate('/login?reset-password=true');
        } catch (e: any) {
            if (e.response.status === 400) {
                setErrors({
                    ...errors,
                    ...e.response.data,
                });
                setIsLoading(false);
            }
            if (e.response.status === 401) {
                setErrors({
                    ...errors,
                    code: 'Invalid or expired token',
                });
                setIsLoading(false);
            }
            if (e.response.status === 500) {
                setShowError(true);
            }
            setIsLoading(false);
        }
    };

    if (showError) {
        return <GenericError />;
    }

    return (
        <div className="reset-password-wrapper">
            <div className="reset-password-main">
                <div className="reset-password-header">
                    <Title text={t('resetPassword.title')} size="xl" />
                </div>
                {!showPasswordForm && (
                    <>
                        <TextInput
                            label={t('common.email')}
                            name="email"
                            placeholder={t('resetPassword.enterEmail')}
                            onChange={inputChangeHandler}
                            value={formValues.email}
                            error={errors.email}
                            className="full-width"
                        />
                        <Button
                            text={t('resetPassword.sendCode')}
                            variant="green"
                            onClick={onSendCode}
                            disabled={isLoading}
                        />
                    </>
                )}
                {showPasswordForm && (
                    <>
                        <TextField>
                            {`We've sent an email to ${formValues.email} with a code to reset your password, paste it in the code input below.`}
                        </TextField>
                        <TextInput
                            label={t('resetPassword.password')}
                            name="password"
                            placeholder={t('login.enterPassword')}
                            onChange={inputChangeHandler}
                            value={formValues.password}
                            error={errors.password}
                            className="full-width"
                            type="password"
                        />
                        <TextInput
                            label={t('resetPassword.confirmPassword')}
                            name="confirmPassword"
                            placeholder={t('resetPassword.confirmPassword')}
                            onChange={inputChangeHandler}
                            value={formValues.confirmPassword}
                            error={errors.confirmPassword}
                            className="full-width"
                            type="password"
                        />
                        <TextInput
                            label={t('resetPassword.code')}
                            name="code"
                            placeholder={t('resetPassword.code')}
                            onChange={inputChangeHandler}
                            value={formValues.code}
                            error={errors.code}
                            className="full-width"
                        />
                        <Button
                            text={t('resetPassword.save')}
                            variant="green"
                            onClick={onResetPassword}
                            disabled={isLoading}
                        />
                    </>
                )}
            </div>
        </div>
    );
}
