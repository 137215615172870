import './title.css';

interface TitleProps {
    text: string | undefined;
    size?: 's' | 'm' | 'l' | 'xl' | 'xxl';
    color?: 'green' | 'red' | 'black' | 'white' | 'secondary-text';
    weight?: 'regular' | 'bold';
}

const Title: React.FC<TitleProps> = ({
    text,
    size = 'm',
    color = 'black',
    weight = 'bold',
}) => {
    return <h3 className={`title ${color} ${size} ${weight}`}>{text}</h3>;
};

export default Title;
