import { IEvent } from '../../../utils/types';
import UserAvatar from '../../common/UserAvatar/UserAvatar';
import { Button, TextField, Title } from '../../common';
import { useTranslations } from '../../../hooks/translations/useTranslations';
import { useNavigate } from 'react-router-dom';
import { renderUserName } from '../../../utils/helpers';
import { useEventRequests } from './useEventRequests';

import './eventRequests.css';

interface EventJoinRequestsProps {
    eventDetails: IEvent;
    isOwner: boolean;
    onRequestAction: (changeTab?: boolean) => void;
}

const EventJoinRequests = ({
    eventDetails,
    isOwner,
    onRequestAction,
}: EventJoinRequestsProps) => {
    const navigate = useNavigate();
    const t = useTranslations();

    const {
        isLoading,
        searchOptions,
        error,
        fetchResults,
        acceptJoinRequest,
        rejectJoinRequest,
    } = useEventRequests();

    async function acceptRequestHandler(requestId: string) {
        await acceptJoinRequest(eventDetails.id, requestId);
        onRequestAction(true);
    }

    async function rejectRequestHandler(requestId: string) {
        await rejectJoinRequest(eventDetails.id, requestId);
        onRequestAction(true);
    }

    const userClickHandler = (userId: string) => {
        navigate(`/users/${userId}`);
    };

    if (eventDetails.joinRequests.length === 0) {
        return (
            <div className="event-join-requests-wrapper">
                <Title text={t('common.joinRequests')} />
                <div className="event-join-request">
                    <TextField>
                        There are no pending requests for this event
                    </TextField>
                </div>
            </div>
        );
    }

    return (
        <div className="event-join-requests-wrapper">
            {/* <Title text={t('common.joinRequests')} /> */}

            {eventDetails.joinRequests.map(request => (
                <div key={request.id} className="event-join-request">
                    <div className="event-join-request-user">
                        <UserAvatar
                            size="m"
                            onClick={() => userClickHandler(request.user.id)}
                        />
                        <TextField>
                            {renderUserName(
                                request.user.name,
                                request.user.surname
                            )}
                        </TextField>
                    </div>
                    {isOwner ? (
                        <>
                            <Button
                                text="Accept"
                                size="sm"
                                variant="green"
                                onClick={() => acceptRequestHandler(request.id)}
                            />
                            <Button
                                text="Refuse"
                                size="sm"
                                variant="transparent"
                                onClick={() => rejectRequestHandler(request.id)}
                            />
                        </>
                    ) : (
                        <TextField weight="bold" size="s">
                            {t('common.pending')}
                        </TextField>
                    )}
                </div>
            ))}
        </div>
    );
};

export default EventJoinRequests;
