import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getNotifications } from '../../store/notification/notificationSlice';

export const useNotifications = () => {
    const notification = useAppSelector(state => state.notification);
    const auth = useAppSelector(state => state.auth);
    const initialized = useRef(false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (
            !notification.isLoading &&
            !notification.error &&
            auth.authData &&
            !initialized.current
        ) {
            initialized.current = true;
            dispatch(getNotifications());
        }
    }, [dispatch, notification, auth]);

    return notification;
};
