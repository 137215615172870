import { useState } from 'react';
import { Title, TextInput, Button, GenericError, TextField } from '../common';
import { useTranslations } from '../../hooks/translations/useTranslations';
import { confirmEmail } from '../../api/auth';
import { useNavigate } from 'react-router-dom';

import './emailCode.css';

interface EmailCodeProps {
    email: string;
}

const EmailCode: React.FC<EmailCodeProps> = ({ email }) => {
    const t = useTranslations();
    const navigate = useNavigate();

    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [emailCode, setEmailCode] = useState('');
    const [emailCodeError, setEmailCodeError] = useState<string | null>(null);

    const codeChangeHandler = (name: string, value: string) => {
        setEmailCode(value);
        setEmailCodeError(null);
    };

    const onConfirmEmailHandler = async (event: React.FormEvent) => {
        event.preventDefault();

        setIsLoading(true);

        if (!emailCode) {
            setEmailCodeError('This field is required');
            setIsLoading(false);
            return;
        }

        try {
            await confirmEmail(email, emailCode);

            navigate('/login?email-confirmed=true');
        } catch (error: any) {
            if (error.response.status === 400) {
                setEmailCodeError('Invalid or expired token');
            } else {
                setError(true);
            }
        }
        setIsLoading(false);
    };

    if (error) {
        return <GenericError />;
    }

    return (
        <div className="email-code">
            <div className="email-code-title">
                <Title text={t('register.confirmEmail')} size="xxl" />
            </div>
            <TextField>
                {`We've sent an email to ${email} with a security code.`}
            </TextField>
            <TextField>
                Paste it in the input below to confirm your email.
            </TextField>
            <form onSubmit={onConfirmEmailHandler} className="register-form">
                <TextInput
                    label={t('register.emailCode')}
                    name="emailCode"
                    placeholder={t('register.emailCode')}
                    onChange={codeChangeHandler}
                    value={emailCode}
                    error={emailCodeError}
                />
                <Button
                    type="submit"
                    text={t('register.confirm')}
                    variant="green"
                    disabled={isLoading}
                />
            </form>
        </div>
    );
};

export default EmailCode;
