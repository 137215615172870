import mockRequest from '../utils/mockRequest';

export const fetchBoardMessagesAPI = async (eventId: string) => {
    const boardMessages = await mockRequest.get(`/boards/${eventId}`);
    return boardMessages.data;
};

export const createBoardMessageAPI = async (
    eventId: string,
    message: string
) => {
    const record = await mockRequest.post(`/boards/${eventId}`, {
        eventId,
        message,
    });
    return record.data;
};
