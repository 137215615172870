import { formatDistanceToNow } from 'date-fns';
import { Icon, TextField, TextInput, UserAvatar } from '../../common';
import { useNavigate, useParams } from 'react-router-dom';
import { COLOURS } from '../../../utils/consts';
import { useTranslations } from '../../../hooks/translations/useTranslations';
import { renderUserName } from '../../../utils/helpers';
import { useEventBoard } from './useEventBoard';

import './eventBoard.css';

interface EventBoardProps {
    isEventCancelled: boolean;
    isCancelledBySystem: boolean;
}

const EventBoard: React.FC<EventBoardProps> = ({
    isEventCancelled = false,
    isCancelledBySystem = false,
}) => {
    const { eventId } = useParams();
    const navigate = useNavigate();
    const t = useTranslations();

    const {
        error,
        boardMessages,
        isLoading,
        newMessage,
        onMessageType,
        onEnterPress,
        onWriteMessage,
    } = useEventBoard(eventId, isEventCancelled);

    const userClickHandler = (userId: string) => {
        navigate(`/users/${userId}`);
    };

    if (isLoading && boardMessages.length === 0) {
        return (
            <div className="event-board-message">
                <TextField>Loading...</TextField>{' '}
            </div>
        );
    }

    return (
        <div className="event-board">
            <div className="event-board-messages">
                {boardMessages.length > 0 ? (
                    boardMessages.map(message => (
                        <div
                            key={message.id}
                            className="event-board-message-row"
                        >
                            <UserAvatar
                                imageSrc={message.user.avatarUrl}
                                size="m"
                                onClick={() =>
                                    userClickHandler(message.user.id)
                                }
                            />
                            <div className="event-board-message">
                                <div>
                                    <TextField weight="bold" size="m">
                                        {renderUserName(
                                            message.user.name,
                                            message.user.surname
                                        )}
                                    </TextField>
                                    <TextField size="s">
                                        {`${formatDistanceToNow(
                                            new Date(message.date)
                                        )} ago`}
                                    </TextField>
                                </div>
                                <TextField size="s">
                                    {message.message}
                                </TextField>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="event-board-message-row">
                        <TextField size="s">
                            {t('eventDetails.noMessages')}
                        </TextField>
                    </div>
                )}
                {isLoading && (
                    <div className="event-board-message">
                        <TextField>Loading...</TextField>{' '}
                    </div>
                )}
            </div>
            {!isEventCancelled && !isCancelledBySystem && (
                <div className="event-board-input">
                    <TextInput
                        value={newMessage}
                        name="newMessage"
                        onChange={onMessageType}
                        placeholder="Write to the board..."
                        isDisabled={isLoading}
                        error={error}
                        onKeyDown={onEnterPress}
                    />
                    <Icon
                        name="bx-send"
                        size="25px"
                        color={isLoading ? '#c0c0c0' : COLOURS.GREEN}
                        cursor="pointer"
                        title={t('eventDetails.sendMessage')}
                        onClick={onWriteMessage}
                    />
                </div>
            )}
        </div>
    );
};

export default EventBoard;
